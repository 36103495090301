@import 'themes';
@import '@nebular/theme/styles/globals';
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@include nb-install() {
  @include nb-theme-global();
}

/* You can add global styles to this file, and also import other style files */

h1,
h2,
h3,
h4,
h5,
text {
  font-family: "Open Sans";
}

h3 {
  font-weight: normal;

  &.screen {
    margin-top: 0;
  }
}

label:not(.toggle-label),
.label {
  text-transform: uppercase;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.white-bg {
  position: relative;

  :before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ' ';
    background-color: white;
    z-index: -100;
  }
}

.team-dialog {
  //width: 60%;

  nb-dialog-container {
    width: 100%;
    display: flex;
  }
}

.bottom-spacer {
  padding-bottom: 2.25rem;
}

.task-toolbar-dialog {
  align-self: flex-end;
  width: 100%;
  padding-left: nb-theme(sidebar-width);

  nb-dialog-container {
    padding: 0 1.5rem;
    width: 100%;
  }
}

nb-menu.main {
  border-top: 1px solid nb-theme(menu-item-divider-color);
  border-bottom: 1px solid nb-theme(menu-item-divider-color);
}

nb-sidebar-footer {
  margin: 1.25rem;
}
nb-user{
  float: left;
}

input[readonly]:not(.preview),
input[readonly].status-basic:not(.preview),
textarea[readonly]:not(.preview),
textarea[readonly].status-basic:not(.preview) {

  &,
  &:hover,
  &:active,
  &:focus {
    &:not(.center) {
      padding-left: 0;
    }

    cursor: default;
    background-color: transparent;
    border: none;
  }
}

nb-layout .layout .layout-container nb-sidebar.details-sidebar {
  top: 0;
}

nb-card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

nb-card-header {
  font-weight: bold;
  font-size: 1.125rem;
  border-bottom: none;
}

.tooltip-preserve-white-space {
  white-space: pre-wrap;
}

.calculation-container {
  max-height: 55vh;
}

body {
  background-color: nb-theme(layout-background-color);
}
